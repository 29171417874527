<template>
  <div class="overflow-hidden bg-gray-50">
    <div class="relative max-w-screen-xl px-4 py-20 mx-auto sm:px-6 lg:px-8">
      <svg
        class="absolute top-0 transform -translate-x-1/2 left-full -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
      </svg>

      <div class="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div class="lg:col-span-1">
          <h3
            class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            A better way to store and view your recipes.
          </h3>
        </div>
        <div class="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
          <div>
            <div
              class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                ></path>
              </svg>
            </div>
            <div class="mt-5">
              <h4 class="text-lg font-medium leading-6 text-gray-900">Easy Discovery</h4>
              <p class="mt-2 text-base leading-6 text-gray-500">
                View all recipes created by other users without having to scroll through someones
                life story
              </p>
            </div>
          </div>
          <div class="mt-10 sm:mt-0">
            <div
              class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                ></path>
              </svg>
            </div>
            <div class="mt-5">
              <h4 class="text-lg font-medium leading-6 text-gray-900">Easy Creation</h4>
              <p class="mt-2 text-base leading-6 text-gray-500">
                Create a recipe via a user friendly recipe form
              </p>
            </div>
          </div>
          <div class="mt-10 sm:mt-0">
            <div
              class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                <path
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                ></path>
              </svg>
            </div>
            <div class="mt-5">
              <h4 class="text-lg font-medium leading-6 text-gray-900">Easy Viewing</h4>
              <p class="mt-2 text-base leading-6 text-gray-500">
                View a list of any saved recipes for easy reference
              </p>
            </div>
          </div>
          <div class="mt-10 sm:mt-0">
            <div
              class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <div class="mt-5">
              <h4 class="text-lg font-medium leading-6 text-gray-900">Totally Free</h4>
              <p class="mt-2 text-base leading-6 text-gray-500">
                Create, edit, and view unlimited recipes for free
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
};
</script>

<style></style>
