<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeMount() {
    this.initAppData();
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },
  methods: {
    async initAppData() {
      this.$store.dispatch('fetchIp');
      // TODO: All recipes/first batch doesn't updated if recipes are added or edited.
      // this.$store.dispatch('getFirstBatchOfRecipes');
      this.$store.dispatch('bindRecentlyAddedRecipes');
    },
  },
};
</script>

<style src="./assets/styles.css"></style>
