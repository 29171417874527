const normalizeUser = (user) => ({
  uid: user.uid,
  displayName: user.displayName,
  email: user.email,
  phoneNumber: user.phoneNumber,
  photoUrl: user.photoURL,
  refreshToken: user.refreshToken,
  emailVerified: user.emailVerified,
});

export default normalizeUser;
