<template>
  <div class="flex flex-col overflow-hidden rounded shadow">
    <!--<div class="flex-shrink-0">
      <img class="object-cover w-full h-48" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="" />
    </div>-->
    <div class="flex flex-col justify-between flex-1 p-6 bg-white">
      <slot name="deleteicon" :deleteRecipe="deleteRecipe"></slot>

      <div class="flex-1">
        <p class="text-sm font-medium leading-5 text-blue-700">
          <span class="hover:underline">
            {{ recipe.category }}
          </span>
        </p>
        <div class="block">
          <h3 class="mt-2 text-xl font-semibold leading-7 text-gray-900">
            {{ recipe.title }}
          </h3>
          <p class="mt-3 text-base leading-6 text-gray-500">
            {{ recipe.description }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-between mt-6">
        <div class="mb-4">
          <div class="flex text-sm leading-5 text-gray-500">
            <time datetime="2020-03-16">
              {{ recipe.createdOn | formatDate }}
            </time>
            <span class="mx-1"> &middot; </span>
            <span> {{ recipe.ingredients.length + 1 }} ingredients </span>
          </div>
        </div>
        <div class="">
          <router-link
            :to="'/recipe/' + recipe.id"
            class="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium leading-5 text-white bg-blue-700 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700"
          >
            View Recipe
          </router-link>

          <slot name="editbutton"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/services/firebase';
import handleErrorCode from '@/util/handleErrorCode';
import dayjs from 'dayjs';

export default {
  name: 'Recipe',
  props: {
    recipe: Object,
  },
  methods: {
    async deleteRecipe() {
      const result = await this.$swal({
        icon: 'warning',
        title: 'Delete Recipe',
        text: `Are you sure you want to delete ${this.recipe.title}?`,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes, Delete',
      });
      if (result.value) {
        this.performDelete();
      }
    },
    async performDelete() {
      try {
        //TODO: when a recipe is deleted, its not removed from the homepage or the all recipes page without a refresh
        await db.collection('recipes').doc(this.recipe.id).delete();

        this.$swal({
          icon: 'success',
          title: 'Recipe deleted!',
        });
      } catch (err) {
        if (err.code) {
          this.errorMsg = handleErrorCode(err.code);
        } else {
          this.errorMsg = err;
        }
        this.$swal({
          icon: 'error',
          text: 'Error deleting, please try again',
        });
      }
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return '-';
      }
      const date = val.toDate();
      return dayjs(date).format('MMM D, YYYY');
    },
  },
};
</script>

<style></style>
