import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import { auth, db } from '@/services/firebase';
import { normalizeUser, mapRecipes } from '@/util';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    limit: 6,
    userIp: null,
    user: null,
    isAuthenticated: false,
    userProfile: null,
    recipesLoading: false,
    userRecipes: [],
    userRecipesLoading: false,
    recipes: [],
    recentlyAddedLimit: 3,
    recentlyAddedLoading: false,
    recentlyAddedRecipes: [],
    disableFetchMore: false,
    lastVisibleRecipeSnapshot: null,
    recipeRef: db.collection('recipes'),
  },
  actions: {
    async fetchIp({ commit }) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const res = await this._vm.$api.user.getUserIp();
        commit('setUserIp', { ip: res.data.ip });
      } catch (err) {
        console.error('error getting ip', err);
      }
    },
    async getFirstBatchOfRecipes({ commit, state }) {
      const query = await state.recipeRef.orderBy('createdOn', 'desc').limit(state.limit).get();
      const recipes = mapRecipes(query);
      const lastVisibleRecipeSnapshot = query.docs[query.docs.length - 1];
      commit('setLastVisibleRecipeSnapshot', { lastVisibleRecipeSnapshot });
      commit('addToRecipes', { recipes });
    },
    async nextBatchOfRecipes({ commit, state }) {
      const query = await state.recipeRef
        .orderBy('createdOn', 'desc')
        .startAfter(state.lastVisibleRecipeSnapshot)
        .limit(state.limit)
        .get();
      const recipes = mapRecipes(query);
      const lastVisibleRecipeSnapshot = query.docs[query.docs.length - 1];
      commit('setLastVisibleRecipeSnapshot', { lastVisibleRecipeSnapshot });
      commit('addToRecipes', { recipes });
      if (query.docs.length === 0) {
        commit('setDisableFetchMore', { disabled: true });
      }
    },
    logoutUser({ commit }) {
      commit('setIsAuthenticated', false);
      commit('setUser', { user: null });
    },
    loginUser({ commit }, user) {
      commit('setUser', { user });
      commit('setIsAuthenticated', true);
    },
    bindUserProfile: firestoreAction(async ({ bindFirestoreRef }, user) => {
      try {
        await bindFirestoreRef('userProfile', db.collection('users').doc(user.uid));
      } catch (err) {
        console.error('error binding userProfile', err);
      }
    }),
    unbindUserProfile: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('userProfile');
    }),
    bindUserRecipes: firestoreAction(async ({ bindFirestoreRef, commit }, user) => {
      commit('setUserRecipesLoading', { loading: true });
      try {
        await bindFirestoreRef(
          'userRecipes',
          db.collection('recipes').where('userId', '==', user.uid).orderBy('createdOn', 'desc')
        );
        commit('setUserRecipesLoading', { loading: false });
      } catch (err) {
        console.error('error binding userRecipes', err);
        commit('setUserRecipesLoading', { loading: false });
      }
    }),
    unbindUserRecipes: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('userRecipes', true);
    }),
    bindRecentlyAddedRecipes: firestoreAction(async ({ bindFirestoreRef, state, commit }) => {
      commit('setRecentlyAddedLoading', { loading: true });
      try {
        await bindFirestoreRef(
          'recentlyAddedRecipes',
          db.collection('recipes').orderBy('createdOn', 'desc').limit(state.recentlyAddedLimit)
        );
        commit('setRecentlyAddedLoading', { loading: false });
      } catch (err) {
        console.error('error binding recentlyAddedRecipes', err);
        commit('setRecentlyAddedLoading', { loading: false });
      }
    }),
    unbindRecentlyAddedRecipes: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('recentlyAddedRecipes', true);
    }),
  },
  mutations: {
    ...vuexfireMutations,
    setUserIp(state, payload) {
      Vue.set(state, 'userIp', payload.ip);
    },
    setUser(state, payload) {
      Vue.set(state, 'user', payload.user);
    },
    setUserProfile(state, payload) {
      Vue.set(state, 'userProfile', payload.userProfile);
    },
    setUserRecipes(state, payload) {
      Vue.set(state, 'userRecipes', payload.userRecipes);
    },
    setUserRecipesLoading(state, payload) {
      Vue.set(state, 'userRecipesLoading', payload.loading);
    },
    setIsAuthenticated(state, value) {
      Vue.set(state, 'isAuthenticated', value);
    },
    setLastVisibleRecipeSnapshot(state, payload) {
      Vue.set(state, 'lastVisibleRecipeSnapshot', payload.lastVisibleRecipeSnapshot);
    },
    setRecipes(state, payload) {
      Vue.set(state, 'recipes', payload.recipes);
    },
    addToRecipes(state, payload) {
      payload.recipes.forEach((r) => state.recipes.push(r));
    },
    setRecipesLoading(state, payload) {
      Vue.set(state, 'recipesLoading', payload.loading);
    },
    setRecentlyAddedLoading(state, payload) {
      Vue.set(state, 'recentlyAddedLoading', payload.loading);
    },
    setDisableFetchMore(state, payload) {
      Vue.set(state, 'disableFetchMore', payload.disabled);
    },
  },
  modules: {},
});

// handle page reloads
// https://stackoverflow.com/questions/54479892/difference-between-get-and-snapshot-in-cloud-firestore
auth().onAuthStateChanged((user) => {
  if (user) {
    const normalizedUser = normalizeUser(user);
    store.dispatch('loginUser', normalizedUser);

    // User Profile Snapshot
    store.dispatch('bindUserProfile', normalizedUser);

    // User Recieps Snapshot
    store.dispatch('bindUserRecipes', normalizedUser);
  } else {
    store.dispatch('unbindUserProfile');
    store.dispatch('unbindUserRecipes');
    store.dispatch('logoutUser');
  }
});

export default store;
