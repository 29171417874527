<template>
  <div data-layout="with-sidebar">
    <nav-bar></nav-bar>
    <main>
      <slot />
    </main>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FootBar from '@/components/FootBar.vue';

export default {
  name: 'WithSidebarLayout',
  components: {
    NavBar,
    FootBar,
  },
};
</script>

<style></style>
