import { map } from 'lodash';
import { normalizeRecipe } from '@/util';

const mapRecipes = (query) => {
  if (!query && !query.docs) return [];
  return map(query.docs, (doc) => {
    return normalizeRecipe(doc);
  });
};

export default mapRecipes;
