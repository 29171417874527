const normalizeRecipe = (doc) => {
  if (!doc.exists) return {};

  return {
    id: doc.id,
    ...doc.data(),
  };
};

export default normalizeRecipe;
