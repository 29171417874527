import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import { auth } from '@/services/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'default', requiresAuth: false },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'default', requiresAuth: false },
    component: () => import(/* webpackChunkName: "group-no-auth" */ '../views/Login.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: { layout: 'default', requiresAuth: false },
    component: () => import(/* webpackChunkName: "group-no-auth" */ '../views/FAQ.vue'),
  },
  {
    path: '/recipe/:id',
    name: 'Recipe',
    meta: { layout: 'default', requiresAuth: false },
    component: () => import(/* webpackChunkName: "group-no-auth" */ '../views/Recipe.vue'),
  },
  /* {
    path: '/all-recipes',
    name: 'All Recipes',
    meta: { layout: 'default', requiresAuth: false },
    component: () => import(webpackChunkName: "group-no-auth" '../views/AllRecipes.vue'),
  },*/
  {
    path: '/add',
    name: 'AddRecipe',
    meta: { layout: 'default', requiresAuth: true },
    component: () => import(/* webpackChunkName: "group-auth" */ '../views/AddRecipe.vue'),
  },
  {
    path: '/edit/:id',
    name: 'EditRecipe',
    meta: { layout: 'default', requiresAuth: true },
    component: () => import(/* webpackChunkName: "group-auth" */ '../views/EditRecipe.vue'),
  },
  {
    path: '/my-recipes',
    name: 'MyRecipes',
    meta: { layout: 'default', requiresAuth: true },
    component: () => import(/* webpackChunkName: "group-auth" */ '../views/MyRecipes.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: 'default', requiresAuth: true },
    component: () => import(/* webpackChunkName: "group-auth" */ '../views/Profile.vue'),
  },
  {
    path: '/import',
    name: 'ImportRecipe',
    meta: { layout: 'default', requiresAuth: true },
    component: () => import(/* webpackChunkName: "group-auth" */ '../views/ImportRecipe.vue'),
  },
  {
    path: '*',
    name: '404',
    meta: { layout: 'default', requiresAuth: false },
    component: () => import(/* webpackChunkName: "group-no-auth" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const { currentUser } = auth();

  if (requiresAuth && !currentUser) {
    next('/login');
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
