import axios from 'axios';
import User from '@/api/user';

const projectApi = axios.create({
  // baseURL: '',
  timeout: 5000,
  headers: {
    // 'Authorization Bearer': Auth.getToken(),
  },
});

export default {
  install(vue) {
    // Add apis to the repository
    const repositories = {
      user: User(projectApi),
    };

    // eslint-disable-next-line no-param-reassign
    vue.prototype.$api = repositories;
  },
};
