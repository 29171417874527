<template>
  <div class="home">
    <Hero />

    <div class="relative px-4 pt-16 pb-20 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="absolute inset-0">
        <div class="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div class="relative mx-auto max-w-7xl">
        <div class="text-center">
          <h2
            class="text-3xl font-extrabold leading-9 tracking-tight text-gray-800 sm:text-4xl sm:leading-10"
          >
            Recently Added
          </h2>
          <p class="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-700 sm:mt-4">
            Your most recently added recipes
          </p>
        </div>

        <div v-if="userRecipesLoading" class="text-center">
          <LoadingSvg />
        </div>
        <div v-else-if="!userRecipesLoading && userRecipes && userRecipes.length > 0">
          <div class="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
            <Recipe :recipe="recipe" :key="recipe.id" v-for="recipe in userRecipes.slice(0, 3)" />
          </div>
          <div class="flex items-center justify-center mt-8">
            <router-link
              to="/my-recipes"
              class="px-5 py-3 text-base font-medium leading-6 text-blue-700 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-blue-500 focus:outline-none focus:shadow-outline"
            >
              View All
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="p-4 mt-8 rounded-md bg-blue-50">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: information-circle -->
                <svg
                  class="w-5 h-5 text-blue-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-3 md:flex md:justify-between">
                <p class="text-sm text-blue-700">You haven't added any recipes</p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                  <router-link
                    to="/add"
                    class="font-medium text-blue-700 whitespace-nowrap hover:text-blue-600"
                    >Add Recipe <span aria-hidden="true">&rarr;</span></router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue-800" v-if="!isAuthenticated">
      <div
        class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <h2
          class="text-3xl font-extrabold leading-9 tracking-tight text-yellow-300 sm:text-4xl sm:leading-10"
        >
          Ready to add a recipe?
          <br />
          <router-link to="/login" class="text-2xl text-white"
            >Sign up to start saving recipes.</router-link
          >
        </h2>
        <div class="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link
              to="/login"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-700 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            >
              Sign Up
            </router-link>
          </div>
          <div class="inline-flex ml-3 rounded-md shadow">
            <router-link
              to="/login"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-blue-700 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:shadow-outline"
            >
              Sign In
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue-800" v-if="isAuthenticated">
      <div
        class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <h2
          class="text-3xl font-extrabold leading-9 tracking-tight text-yellow-300 sm:text-4xl sm:leading-10"
        >
          All Recipes
          <br />
          <router-link to="/login" class="text-2xl text-white"
            >View all your added recipes</router-link
          >
        </h2>
        <div class="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link
              to="/my-recipes"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-blue-800 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:shadow-outline"
            >
              View All
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Hero from '@/components/Hero.vue';
import Recipe from '@/components/Recipe.vue';
import LoadingSvg from '@/components/LoadingSvg.vue';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Recipeazy',
  },
  components: {
    Hero,
    Recipe,
    LoadingSvg,
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
      userRecipes: (state) => state.userRecipes,
      userRecipesLoading: (state) => state.userRecipesLoading,
      // recentlyAddedLoading: (state) => state.recentlyAddedLoading,
      // recentlyAddedRecipes: (state) => state.recentlyAddedRecipes,
    }),
  },
  methods: {},
};
</script>

<style>
.recently-bg {
  background-image: url('~@/assets/trianglify-lowres.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
