<template>
  <nav class="bg-gray-800">
    <!-- DESKTOP NAV -->
    <div class="px-4 mx-auto desktop-nav max-w-7xl sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0 text-gray-400">
            <!-- The Logo --->
            <!--<img class="block w-auto h-8 lg:hidden" src="/img/logos/workflow-mark-on-dark.svg" alt="" />
            <img class="hidden w-auto h-8 lg:block" src="/img/logos/workflow-logo-on-dark.svg" alt="" />-->
            <router-link to="/">
              <p>Recipeazy</p>
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex">
              <!--<router-link
                to="/all-recipes"
                exactActiveClass="is-active"
                class="px-3 py-2 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >All Recipes</router-link
              >-->
              <router-link
                v-if="isAuthenticated"
                to="/my-recipes"
                exactActiveClass="is-active"
                class="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >My Recipes</router-link
              >
              <router-link
                v-if="isAuthenticated"
                to="/add"
                exactActiveClass="is-active"
                class="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >Add</router-link
              >
              <router-link
                v-if="isAuthenticated"
                to="/import"
                exactActiveClass="is-active"
                class="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >Import</router-link
              >
              <router-link
                to="/faq"
                exactActiveClass="is-active"
                class="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >FAQ</router-link
              >
              <router-link
                v-if="!isAuthenticated"
                to="/login"
                exactActiveClass="is-active"
                class="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >Login</router-link
              >
            </div>
          </div>
        </div>
        <div v-if="isAuthenticated" class="hidden sm:ml-6 sm:block">
          <div class="flex items-center">
            <div class="relative ml-3">
              <div>
                <button
                  id="profileAvatar"
                  aria-label="User menu"
                  aria-haspopup="true"
                  @click.stop="profileOpen = !profileOpen"
                  class="flex text-sm transition duration-150 ease-in-out border-2 border-transparent rounded-full focus:outline-none focus:border-white"
                >
                  <img v-if="user.photoUrl" class="w-8 h-8 rounded-full" :src="user.photoUrl" />
                  <span v-else class="w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                    <svg
                      class="w-full h-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 20"
                    >
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <div
                  v-show="profileOpen"
                  class="absolute right-0 z-10 w-48 mt-2 origin-top-right rounded-md shadow-lg"
                  v-click-outside="onClickOutsideProfile"
                >
                  <div class="py-1 bg-white rounded-md shadow-xs">
                    <p class="px-4 py-2 text-sm leading-5 text-gray-700">
                      {{ user.email }}
                    </p>
                    <router-link
                      to="/profile"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                      >Profile</router-link
                    >
                    <router-link
                      to="/my-recipes"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                      >My Recipes</router-link
                    >
                    <button
                      @click="logout"
                      class="block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex -mr-2 sm:hidden">
          <button
            id="navIcon"
            @click.stop="navOpen = !navOpen"
            class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
          >
            <svg class="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path
                :class="{ hidden: navOpen, 'inline-flex': !navOpen }"
                class=""
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
              <path
                :class="{ hidden: !navOpen, 'inline-flex': navOpen }"
                class=""
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- MOBILE NAV -->
    <div
      :class="{ block: navOpen, hidden: !navOpen }"
      class="mobile-nav sm:hidden"
      v-click-outside="onClickOutsideNav"
    >
      <div class="px-2 pt-2 pb-3">
        <!--<router-link
          to="/all-recipes"
          exactActiveClass="is-active"
          class="block px-3 py-2 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >All Recipes</router-link-->
        <router-link
          v-if="isAuthenticated"
          to="/my-recipes"
          exactActiveClass="is-active"
          class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >My Recipes</router-link
        >
        <router-link
          v-if="isAuthenticated"
          to="/add"
          exactActiveClass="is-active"
          class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Add</router-link
        >
        <router-link
          v-if="isAuthenticated"
          to="/import"
          exactActiveClass="is-active"
          class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Import</router-link
        >
        <router-link
          to="/faq"
          exactActiveClass="is-active"
          class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >FAQ</router-link
        >
        <router-link
          v-if="!isAuthenticated"
          to="/login"
          exactActiveClass="is-active"
          class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md nav-link hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Login</router-link
        >
      </div>
      <div v-if="isAuthenticated" class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
              <img v-if="user.photoUrl" class="w-8 h-8 rounded-full" :src="user.photoUrl" />
              <svg
                v-else
                class="w-full h-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 20"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-6 text-white">
              {{ user.displayName }}
            </div>
            <div class="text-sm font-medium leading-5 text-gray-400">
              {{ user.email }}
            </div>
          </div>
        </div>
        <div class="px-2 mt-3">
          <router-link
            to="/profile"
            href="#"
            class="block px-3 py-2 text-base font-medium text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >Profile</router-link
          >
          <button
            @click="logout"
            class="block px-3 py-2 mt-1 text-base font-medium text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >
            Sign out
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '@/services/firebase';

export default {
  name: 'NavBar',
  data() {
    return {
      navOpen: false,
      profileOpen: false,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
      user: (state) => state.user,
    }),
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    $route() {
      this.navOpen = false;
      this.profileOpen = false;
    },
  },
  methods: {
    closeProfile() {
      this.profileOpen = false;
    },
    closeNav() {
      this.navOpen = false;
    },
    async logout() {
      try {
        await auth().signOut();
        this.$router.push('/login');
      } catch (err) {
        console.error('logout error!', err);
      }
    },
    onClickOutsideProfile() {
      if (this.profileOpen) {
        this.closeProfile();
      }
    },
    onClickOutsideNav() {
      if (this.navOpen) {
        this.closeNav();
      }
    },
  },
};
</script>

<style></style>
