const handleErrorCode = ({ code }) => {
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html
  let message = '';
  switch (code) {
    case 'auth/invalid-email':
      message = 'The email is invalid. Please try again.';
      break;
    case 'auth/user-not-found':
      message = 'An account associated with this email was not found.';
      break;
    case 'auth/user-disabled':
      message = 'The account associated with this email has been disabled.';
      break;
    case 'auth/wrong-password':
      message = 'The password entered is incorrect.';
      break;
    case 'auth/weak-password':
      message = 'The password entered is too weak. Please try a stronger one.';
      break;
    case 'auth/email-already-in-use':
      message = 'This email is already associated with another account.';
      break;
    default:
      message = 'Ooops, there was an error. Please try again or contact support';
  }
  return message;
};

export default handleErrorCode;
