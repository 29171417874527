import Vue from 'vue';
import DefaultLayout from '@/layouts/Default.vue';
import WithSidebar from '@/layouts/WithSidebar.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import api from '@/api';
import { auth } from '@/services/firebase';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueMeta from 'vue-meta';
import vClickOutside from 'v-click-outside';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueFormulate from '@braid/vue-formulate';
import VueGtag from 'vue-gtag';

// testurl: http://lsbtestrecipelib.s3-website-us-east-1.amazonaws.com/

Vue.use(api, { optionstest: 'hello' });

Vue.component('default-layout', DefaultLayout);
Vue.component('with-sidebar-layout', WithSidebar);

Vue.use(VueSweetalert2);
Vue.use(VueMeta);
Vue.use(vClickOutside);

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.use(VueFormulate, {
  classes: {
    outer: 'mb-4 formulate-input',
    input(context) {
      switch (context.classification) {
        case 'select':
          return 'mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md';
        case 'button':
          return 'relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium leading-5 text-white bg-blue-700 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700';
        default:
          return 'block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5';
      }
    },
    label: 'font-medium text-sm',
    help: 'text-xs mb-1 text-gray-600',
    error: 'text-red-700 text-xs mb-1',
  },
  plugins: [],
});

Vue.use(VueGtag, {
  config: { id: `${process.env.VUE_APP_GTAG}` },
});

// Init the app inside onAuthStateChanged so we can tell if a user authenticated before rendering a route
// otherwise, if a user is authenticated, they will get redirected to the login page even if they are authenticated
let app;
auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      render: (h) => h(App),
    });
  }
});
