import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.VUE_APP_FB_API_KEY || '',
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN || '',
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL || '',
  projectId: process.env.VUE_APP_FB_PROJECT_ID || '',
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET || '',
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID || '',
  appId: process.env.VUE_APP_FB_APP_ID || '',
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID || '',
};

// init firebase
firebase.initializeApp(config);

const { auth } = firebase;
const db = firebase.firestore();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export { auth, db, serverTimestamp, getCurrentUser };
